<template>
  <div>
    <div class="flex items-center">
      <ALabel
        v-if="label"
        :for="id"
        :optional="optional"
        :mandatory="mandatory"
      >
        {{ label }}
      </ALabel>
    </div>
    <div class="relative mt-1">
      <Component
        :is="type !== 'textarea' ? 'input' : 'textarea'"
        :id="id"
        :type="dynamicType"
        :step="step"
        :rows="rows"
        class="block w-full rounded-md border bg-white px-3 py-2 text-base focus:outline-none disabled:cursor-not-allowed disabled:bg-gray-100"
        :class="[
          error
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-1 focus:ring-red-500'
            : 'border-gray-300 placeholder-gray-400 focus:border-lime-500 focus:ring-1 focus:ring-lime-500',
          {
            'pr-12': type === 'password',
          },
          inputClasses,
        ]"
        :placeholder="placeholder"
        :value="modelValue"
        :autocomplete="autocomplete"
        :autocorrect="autocorrect"
        :autocapitalize="autocapitalize"
        :disabled="disabled"
        @input="$emit('update:modelValue', $event.target.value)"
        @wheel.passive="$event.target.blur()"
      />
      <button
        v-if="type === 'password'"
        v-tippy="{ content: showPassword ? 'Hide password' : 'Show password' }"
        type="button"
        class="absolute bottom-0 right-0 top-0 flex h-full w-12 items-center justify-center text-gray-400 focus:outline-none"
        @click="showPassword = !showPassword"
      >
        <EyeSlashRegular v-if="showPassword" class="h-6 w-6" />
        <EyeRegular v-else class="h-6 w-6" />
      </button>
      <slot name="relative-to-input" />
    </div>
    <AError v-if="error" class="mt-1">
      {{ error }}
      <template v-if="error === 'You already have an account.'">
        You can login
        <InertiaLink href="/login" class="underline">here</InertiaLink>.
      </template>
      <template
        v-if="error === 'Incorrect email or password.' && type !== 'password'"
      >
        You can register
        <InertiaLink href="/register" class="underline">here</InertiaLink>
        if you don't have an account.
      </template>
    </AError>
  </div>
</template>

<script>
import AError from './AError.vue';
import ALabel from './ALabel.vue';
import { v4 as uuid } from 'uuid';
import EyeSlashRegular from '@/phosphoricons/EyeSlashRegular.vue';
import EyeRegular from '@/phosphoricons/EyeRegular.vue';

export default {
  components: { EyeRegular, EyeSlashRegular, ALabel, AError },
  inheritAttrs: false,
  props: {
    type: {
      type: String,
      default: 'text',
    },
    step: {
      type: Number,
      default: 1,
    },
    rows: {
      type: Number,
      default: 3,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: null,
    },
    autocorrect: {
      type: String,
      default: null,
    },
    autocapitalize: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
    inputClasses: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      id: null,
      showPassword: false,
    };
  },
  computed: {
    dynamicType() {
      if (this.type === 'password' && this.showPassword) {
        return 'text';
      }

      return this.type !== 'textarea' ? this.type : undefined;
    },
  },
  created() {
    this.id = `text-input-${uuid()}`;
  },
  methods: {
    focus() {
      this.$refs.input.focus();
    },
    select() {
      this.$refs.input.select();
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end);
    },
  },
};
</script>
