<template>
  <details class="overflow-hidden rounded-xl border border-gray-200 bg-white">
    <summary
      class="flex w-full cursor-pointer items-center justify-between p-4 text-left font-semibold text-gray-900 sm:p-5"
    >
      <h3 class="text-left">
        <slot name="question" />
      </h3>
      <span aria-hidden="true" class="expanded-icon ml-4"
        ><svg
          class="h-6 w-6 text-gray-900"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M20 12H4"
          ></path></svg></span
      ><span aria-hidden="true" class="collapsed-icon ml-4"
        ><svg
          class="h-6 w-6 text-gray-900"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M12 4v16m8-8H4"
          ></path></svg
      ></span>
    </summary>
    <div>
      <div class="max-w-none p-4 pt-0 sm:p-5 sm:pt-0">
        <slot name="answer" />
      </div>
    </div>
  </details>
</template>

<script>
export default {};
</script>

<style scoped>
details .expanded-icon {
  display: none !important;
}

details .collapsed-icon {
  display: block;
}

details[open] .collapsed-icon {
  display: none;
}

details[open] .expanded-icon {
  display: block !important;
}

summary::-webkit-details-marker {
  display: none;
}
</style>
