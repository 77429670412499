<template>
  <DashboardWrapper title="Support">
    <ACard class="mb-4">
      <p>
        We're always happy to assist you with any questions regarding your menu.
      </p>
      <p class="mb-2 mt-4">
        You can talk to us using these communication channels:
      </p>
      <p>
        <strong>Email: </strong>
        <a href="mailto:support@apetitomenu.com" class="underline"
          >support@apetitomenu.com</a
        >
      </p>
      <p>
        <strong>WhatsApp: </strong>
        <a href="https://wa.me/971527565719" class="underline"
          >+971 52 756 5719</a
        >
      </p>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';

export default {
  components: {
    ACard,
    DashboardWrapper,
  },
};
</script>
