import '@/../css/app/index.css';
import { createApp, h } from 'vue';
import { createInertiaApp, Link, router } from '@inertiajs/vue3';
import * as Luxon from 'luxon';
import vClickOutside from 'click-outside-vue3';
import setUserOrigin from '@/utils/setUserOrigin';

import Toast, { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import { plugin as VueTippy } from 'vue-tippy';
import 'tippy.js/dist/tippy.css';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import DashboardLayout from '@app/Shared/DashboardLayout.vue';

Luxon.Settings.defaultLocale = 'en';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.register('/sw-app.js');
}

Bugsnag.start({
  apiKey: window['sharedData']['global']['keys']['bugsnag'],
  plugins: [new BugsnagPluginVue()],
});

function syncHeight() {
  const documentElementClientHeight = document.documentElement.clientHeight;
  const windowOuterHeight = window.outerHeight;

  let height = documentElementClientHeight;

  // [iOS] standalone
  if (navigator.standalone) {
    height = windowOuterHeight;
  }

  document.documentElement.style.setProperty(`--screen-height`, `${height}px`);
}
syncHeight();
window.addEventListener('resize', syncHeight);
window.addEventListener('orientationchange', syncHeight);

// Global form method spoofing
router.put = (url, data, options) =>
  router.post(url, { ...(data ?? {}), _method: 'put' }, options);
router.patch = (url, data, options) =>
  router.post(url, { ...(data ?? {}), _method: 'patch' }, options);
router.delete = (url, options) =>
  router.post(
    url,
    { ...(options?.data ?? {}), _method: 'delete' },
    { ...options, data: undefined },
  );

router.on('start', (event) => {
  if (event.detail.visit.url.pathname === '/logout') {
    window.posthog && window.posthog.reset();
  }
});

router.on('navigate', (event) => {
  const user = event.detail.page.props._user;

  if (user) {
    window.posthog &&
      window.posthog.identify(`${user.id}`, {
        email: user.email,
        name: user.name,
      });
  }

  window.posthog && window.posthog.capture('$pageview');

  window.fbq && window.fbq('track', 'PageView');
});

createInertiaApp({
  progress: {
    delay: 0,
    color: '#83CC15',
  },
  resolve: (name) => {
    const pages = import.meta.glob('./Pages/**/*.vue', { eager: true });
    const page = pages[`./Pages/${name}.vue`];
    if (name.startsWith('App/Dashboard/') && !page.default.layout) {
      page.default.layout = DashboardLayout;
    }
    return page;
  },
  setup({ el, App, props, plugin }) {
    const createdApp = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(Bugsnag.getPlugin('vue'))
      .use(VueTippy, { defaultProps: { placement: 'top' } })
      .use(Toast, {
        transition: 'Vue-Toastification__fade',
        timeout: 2000,
      })
      .use(vClickOutside)
      .component('InertiaLink', Link);

    createdApp.config.globalProperties.$toast = useToast();
    createdApp.config.globalProperties.$getPrimaryTranslation = (
      translations,
    ) => {
      return translations[
        createdApp.config.globalProperties.$page.props._restaurant.languages[0]
      ];
    };
    createdApp.config.globalProperties.$formatPrice = (price) => {
      let fractionDigits =
        createdApp.config.globalProperties.$page.props._restaurant
          .currencyFractionDigits;

      return `${new Intl.NumberFormat('en-US', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
      }).format(price)}`;
    };
    createdApp.config.globalProperties.$formatDateTime = (date, format) => {
      return Luxon.DateTime.fromISO(date).toFormat(format);
    };
    createdApp.config.globalProperties.$luxon = Luxon;

    createdApp.mount(el);
  },
});

setUserOrigin();
