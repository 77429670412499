<template>
  <div
    :style="{
      width: `${size}px`,
      height: `${fileMeta ? size / previewAspectRatio : size}px`,
    }"
  >
    <div
      v-if="fileMeta"
      class="relative h-full w-full overflow-hidden rounded-md border"
    >
      <img
        v-if="fileMeta.type === 'image'"
        class="h-full w-full object-cover"
        :src="fileMeta.url"
        alt=""
      />
      <video
        v-else-if="fileMeta.type === 'video'"
        controls
        class="h-full w-full object-cover"
      >
        <source :src="fileMeta.url" type="video/mp4" />
      </video>
      <div v-else class="flex h-full w-full items-center justify-center p-4">
        <a
          :href="fileMeta.url"
          target="_blank"
          class="block text-center text-gray-900 underline"
          >{{ fileMeta.name }}</a
        >
      </div>

      <button
        v-tippy="{ content: 'Delete' }"
        type="button"
        class="absolute right-2 top-2 z-10 flex h-8 w-8 items-center justify-center rounded-full border border-dashed border-gray-200 bg-white text-gray-700 hover:border-red-500 hover:text-red-500"
        @click="$emit('delete')"
      >
        <TrashBold class="h-4 w-4" />
      </button>
    </div>
    <div v-else class="h-full w-full">
      <FilePicker :id="id" :loading="emittingFile" @file="onFile">
        <template #loading> Previewing file... </template>
        <template #description>
          <p>Max file size is {{ maxSizeInMegabytes }}MB.</p>
          <p class="mt-1">
            Only {{ allowedExtensions.join(', ') }} files are supported.
          </p>
        </template>
      </FilePicker>
    </div>
  </div>
</template>

<script>
import FilePicker from '@app/Shared/FilePicker.vue';
import TrashBold from '@/phosphoricons/TrashBold.vue';
import Compressor from 'compressorjs';
import notifyError from '@/utils/notifyError';

export default {
  components: { TrashBold, FilePicker },
  props: {
    size: {
      type: Number,
      default: 192,
    },
    file: {
      type: Blob,
      default: null,
    },
    imageUrl: {
      type: String,
      default: null,
    },
    videoUrl: {
      type: String,
      default: null,
    },
    fileUrl: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    allowedExtensions: {
      type: Array,
      required: true,
    },
    maxSizeInMegabytes: {
      type: Number,
      required: true,
    },
    previewAspectRatio: {
      type: Number,
      default: 1,
    },
  },
  emits: ['delete', 'file'],
  data() {
    return {
      emittingFile: false,
    };
  },
  computed: {
    fileMeta() {
      if (this.file) {
        return {
          name: this.file.name,
          type: (() => {
            if (this.file.type.startsWith('image/')) {
              return 'image';
            }
            if (this.file.type.startsWith('video/')) {
              return 'video';
            }
          })(),
          url: URL.createObjectURL(this.file),
        };
      }
      if (this.imageUrl) {
        return {
          name: this.imageUrl.split('/').reverse()[0],
          type: 'image',
          url: this.imageUrl,
        };
      }
      if (this.videoUrl) {
        return {
          name: this.videoUrl.split('/').reverse()[0],
          type: 'video',
          url: this.videoUrl,
        };
      }
      if (this.fileUrl) {
        return {
          name: this.fileUrl.split('/').reverse()[0],
          url: this.fileUrl,
        };
      }
      return null;
    },
  },
  methods: {
    onFile(file) {
      this.emittingFile = true;

      this.emitFile(file).finally(() => {
        this.emittingFile = false;
      });
    },
    async emitFile(file) {
      const extension = file.name.split('.').reverse()[0].toLowerCase();

      if (
        this.allowedExtensions &&
        !this.allowedExtensions.includes(extension)
      ) {
        this.$toast.error(
          `Only ${this.allowedExtensions.join(', ')} files are supported.`,
        );
        throw new Error(
          `Only ${this.allowedExtensions.join(', ')} files are supported, ${extension} provided.`,
        );
      }

      try {
        console.log('Size before optimization: ' + file.size);

        file = await this.compressFile(file);

        console.log('Size after optimization: ' + file.size);
      } catch (error) {
        console.error(error);

        notifyError(error);
      }

      if (
        this.maxSizeInMegabytes &&
        file.size > this.maxSizeInMegabytes * 1024 * 1024
      ) {
        this.$toast.error(`Max file size is ${this.maxSizeInMegabytes}MB.`);
        throw new Error(
          `Max file size is ${this.maxSizeInMegabytes}MB, provided ${file.size / 1024 / 1024}MB.`,
        );
      }

      this.$emit('file', file);
    },
    compressFile(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.8,
          maxWidth: 3072,
          maxHeight: 3072,
          success: resolve,
          error: reject,
        });

        const timeoutMs = 3000;

        // Fallback logic to avoid getting into stuck state.
        setTimeout(() => {
          reject(
            `Compression timed out after ${timeoutMs}ms, using original file.`,
          );
        }, timeoutMs);
      });
    },
  },
};
</script>
