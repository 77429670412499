<template>
  <teleport to="body">
    <Transition
      appear
      enter-active-class="ease-out duration-300"
      enter-from-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-in duration-200"
      leave-from-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div
        class="fixed inset-0 z-40 flex cursor-default overflow-auto bg-gray-100/75 px-3 pb-3 pt-3 backdrop-blur backdrop-filter"
        @click.self="onClickOutside"
      >
        <Transition
          appear
          enter-active-class="duration-300 ease-out"
          enter-from-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="duration-200 ease-in"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <div
            class="relative z-30 m-auto flex max-h-[calc(100vh-2rem)] max-h-[calc(var(--screen-height)-2rem)] w-full flex-col rounded-lg bg-white shadow-2xl transition"
            :style="{
              'min-width': `${minWidth}px`,
              'max-width': `${maxWidth}px`,
            }"
            :class="{
              'scale-[0.98]': justClickedOutside,
            }"
          >
            <button
              type="button"
              class="absolute -right-3 -top-3 flex h-10 w-10 items-center justify-center rounded-lg border border-gray-200/50 bg-white shadow-md transition hover:bg-gray-50"
              @click="close"
            >
              <XRegular class="h-5 w-5 text-gray-600" />
            </button>
            <div
              class="border-b border-gray-200 pl-4 pr-8 pt-3 empty:hidden"
              :class="{
                'pb-3': !noBottomPaddingForHeader,
              }"
            >
              <slot name="header" />
            </div>
            <div
              class="scrollbar scrollbar-track-gray flex-1 overflow-y-auto px-4 py-5"
            >
              <slot />
            </div>
            <div
              class="rounded-b-md border-t border-gray-200 bg-gray-50 px-4 py-3 empty:hidden"
            >
              <slot name="footer" />
            </div>
          </div>
        </Transition>
      </div>
    </Transition>
  </teleport>
</template>

<script>
import XRegular from '@/phosphoricons/XRegular.vue';

export default {
  components: { XRegular },
  inheritAttrs: false,
  props: {
    minWidth: {
      type: Number,
      default: 300,
    },
    maxWidth: {
      type: Number,
      default: 500,
    },
    noBottomPaddingForHeader: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  data() {
    return {
      justClickedOutside: false,
    };
  },
  mounted() {
    document.body.classList.add('overflow-hidden');
    window.addEventListener('keydown', this.onKeydown);
  },
  beforeUnmount() {
    document.body.classList.remove('overflow-hidden');
    window.removeEventListener('keydown', this.onKeydown);
  },
  methods: {
    onKeydown(event) {
      if (event.key === 'Escape') {
        this.close();
      }
    },
    close() {
      this.$emit('close');
    },
    onClickOutside() {
      this.justClickedOutside = true;

      setTimeout(() => {
        this.justClickedOutside = false;
      }, 100);
    },
  },
};
</script>
