<template>
  <AModal no-bottom-padding-for-header @close="$emit('close')">
    <template #header>
      <ATranslationTabs v-model:selected-language-code="selectedLanguageCode" />
    </template>

    <div class="space-y-4">
      <AInput
        v-model="form.title[selectedLanguageCode]"
        label="Title"
        placeholder="Customer Survey"
        :error="form.errors[`title.${selectedLanguageCode}`]"
      />

      <div class="flex flex-col">
        <AError v-if="form.errors['questions']">
          {{ form.errors['questions'] }}
        </AError>
        <div
          v-for="(question, questionIndex) in form.questions"
          :key="questionIndex"
          class="flex flex-col"
        >
          <div class="mb-2 flex items-center font-semibold">
            <div class="mr-2">Question #{{ questionIndex + 1 }}</div>
            <button
              v-if="form.questions.length > 1"
              v-tippy="{ content: 'Delete' }"
              type="button"
              class="text-gray-400 hover:text-red-500"
              @click="deleteQuestion(questionIndex)"
            >
              <TrashBold class="h-4 w-4" />
            </button>
          </div>

          <div
            class="mb-3 grid grid-cols-5 gap-x-4 gap-y-2 rounded border border-dashed px-3 py-2"
          >
            <div class="col-span-5">
              <div class="mb-2">
                <AInput
                  v-model="question.question[selectedLanguageCode]"
                  label="Question"
                  :error="
                    form.errors[
                      `questions.${questionIndex}.question.${selectedLanguageCode}`
                    ]
                  "
                  placeholder="How satisfied are you with your experience today?"
                />
              </div>
            </div>

            <div class="col-span-3">
              <ASelect
                v-model="question.type"
                label="Answer type"
                :options="typeOptions"
                :value-resolver="(type) => type.type"
                :text-resolver="(type) => type.name"
                :error="form.errors[`questions.${questionIndex}.type`]"
                empty-item-text="Select type"
                empty-item-disabled
              />
            </div>

            <div class="col-span-2">
              <ALabel> Required? </ALabel>
              <input
                v-model="question.isMandatory"
                type="checkbox"
                class="mt-3"
              />
            </div>
          </div>
        </div>
        <div class="flex">
          <AButton variant="outline-primary" @click="addQuestion">
            + Add question
          </AButton>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex space-x-2">
        <AButton class="mt-3" :loading="form.processing" @click="submit">
          {{ existingForm ? 'Save form' : 'Create form' }}
        </AButton>
        <AButton variant="outline-secondary" @click="$emit('close')">
          Cancel
        </AButton>
      </div>
    </template>
  </AModal>
</template>
<script>
import AInput from '@app/Shared/AInput.vue';
import AButton from '@app/Shared/AButton.vue';
import { useForm } from '@inertiajs/vue3';
import AModal from '@app/Shared/AModal.vue';
import AError from '@app/Shared/AError.vue';
import ASelect from '@app/Shared/ASelect.vue';
import ALabel from '@app/Shared/ALabel.vue';
import cloneDeep from 'lodash/cloneDeep';
import ATranslationTabs from '@app/Shared/ATranslationTabs.vue';
import HasTranslationTabs from '@app/Mixins/HasTranslationTabs.js';
import TrashBold from '@/phosphoricons/TrashBold.vue';

export default {
  components: {
    TrashBold,
    ATranslationTabs,
    ALabel,
    ASelect,
    AError,
    AModal,
    AButton,
    AInput,
  },
  mixins: [HasTranslationTabs],
  props: {
    existingForm: {
      type: Object,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      form: useForm({
        title: this.existingForm ? { ...this.existingForm.title } : {},
        questions: this.existingForm
          ? cloneDeep(this.existingForm.questions)
          : [],
      }),
    };
  },
  computed: {
    typeOptions() {
      return [
        {
          type: 'rating',
          name: 'Rating (1-5 stars)',
        },
        // {
        //   type: "bool",
        //   name: "Bool (yes/no)",
        // },
        {
          type: 'text',
          name: 'Text',
        },
      ];
    },
  },
  mounted() {
    if (this.form.questions.length === 0) {
      this.addQuestion();
    }
  },
  methods: {
    async submit() {
      if (!this.existingForm) {
        await this.form.post('/forms', {
          onSuccess: () => {
            this.$emit('close');
            this.$toast.success('Saved');
          },
          onError: () => {
            this.$toast.error('Please fix all highlighted fields');
          },
          preserveScroll: true,
        });
      } else {
        await this.form.put(`/forms/${this.existingForm.id}`, {
          onSuccess: () => {
            this.$emit('close');
            this.$toast.success('Saved');
          },
          onError: () => {
            this.$toast.error('Please fix all highlighted fields');
          },
          preserveScroll: true,
        });
      }
    },
    addQuestion() {
      this.form.questions.push({
        id: null,
        question: {}, // translatable
        type: null,
        isMandatory: false,
      });
    },
    deleteQuestion(questionIndex) {
      this.form.questions.splice(questionIndex, 1);
      this.form.clearErrors(
        ...Object.keys(this.form.errors).filter((field) =>
          field.startsWith('questions'),
        ),
      );
    },
  },
};
</script>
