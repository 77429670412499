<template>
  <AModal no-bottom-padding-for-header @close="$emit('close')">
    <template #header>
      <ATranslationTabs v-model:selected-language-code="selectedLanguageCode" />
    </template>

    <div class="space-y-4">
      <AutoTranslateToggle
        v-model:auto-translate="form.autoTranslate"
        :selected-language-code="selectedLanguageCode"
      />

      <AInput
        v-model="form.name[selectedLanguageCode]"
        label="Modifier set name"
        placeholder="Choose your milk"
        :error="form.errors[`name.${selectedLanguageCode}`]"
        :mandatory="isPrimaryLanguageSelected"
        :disabled="areTranslatableFieldsDisabled"
      />

      <div class="flex">
        <div class="flex-1">
          <AInput
            v-model="form.minSelection"
            label="Min selection"
            placeholder="1"
            :error="form.errors['minSelection']"
            mandatory
          />
        </div>

        <div class="w-4"></div>

        <div class="flex-1">
          <AInput
            v-model="form.maxSelection"
            label="Max selection"
            placeholder="1"
            :error="form.errors['maxSelection']"
            mandatory
          />
        </div>
      </div>

      <div class="flex flex-col rounded border border-dashed p-3">
        <div class="mb-2 font-semibold">Modifiers</div>
        <AError v-if="form.errors['modifiers']">
          {{ form.errors['modifiers'] }}
        </AError>
        <div
          v-for="(modifier, modifierIndex) in form.modifiers"
          :key="modifierIndex"
          class="relative flex flex-col"
        >
          <div
            class="mb-3 grid grid-cols-6 gap-4 rounded border border-dashed px-3 py-2"
          >
            <div class="col-span-3">
              <div class="mb-2">
                <AInput
                  v-model="modifier.name[selectedLanguageCode]"
                  label="Modifier name"
                  :error="
                    form.errors[
                      `modifiers.${modifierIndex}.name.${selectedLanguageCode}`
                    ]
                  "
                  :mandatory="isPrimaryLanguageSelected"
                  placeholder="Coconut milk"
                  :disabled="areTranslatableFieldsDisabled"
                />
              </div>
            </div>

            <div class="col-span-3">
              <AInput
                v-model="modifier.price"
                type="number"
                :step="
                  1 /
                  Math.pow(10, $page.props._restaurant.currencyFractionDigits)
                "
                :placeholder="
                  $page.props._restaurant.currencyFractionDigits > 0
                    ? `9.${'0'.repeat($page.props._restaurant.currencyFractionDigits)}`
                    : '9'
                "
                :error="form.errors[`modifiers.${modifierIndex}.price`]"
                label="Price"
                class="mr-4"
              />
            </div>
          </div>

          <div class="absolute -right-2 -top-2">
            <button
              v-if="form.modifiers.length > 1"
              v-tippy="{ content: 'Delete' }"
              type="button"
              class="flex h-8 w-8 items-center justify-center rounded-full border border-dashed border-gray-200 bg-white text-gray-700 hover:border-red-500 hover:text-red-500"
              @click="deleteModifier(modifierIndex)"
            >
              <TrashBold class="h-4 w-4" />
            </button>
          </div>
        </div>
        <div class="flex">
          <AButton variant="outline-primary" @click="addModifier">
            + Add modifier
          </AButton>
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex space-x-2">
        <AButton class="mt-3" :loading="form.processing" @click="submit">
          {{
            existingModifierSet ? 'Save modifier set' : 'Create modifier set'
          }}
        </AButton>
        <AButton variant="outline-secondary" @click="$emit('close')">
          Cancel
        </AButton>
      </div>
    </template>
  </AModal>
</template>
<script>
import AInput from '@app/Shared/AInput.vue';
import AButton from '@app/Shared/AButton.vue';
import { useForm } from '@inertiajs/vue3';
import AError from '@app/Shared/AError.vue';
import AModal from '@app/Shared/AModal.vue';
import cloneDeep from 'lodash/cloneDeep';
import HasTranslationTabs from '@app/Mixins/HasTranslationTabs.js';
import ATranslationTabs from '@app/Shared/ATranslationTabs.vue';
import AutoTranslateToggle from '@app/Shared/AutoTranslateToggle.vue';
import TrashBold from '@/phosphoricons/TrashBold.vue';

export default {
  components: {
    TrashBold,
    AutoTranslateToggle,
    ATranslationTabs,
    AModal,
    AError,
    AButton,
    AInput,
  },
  mixins: [HasTranslationTabs],
  props: {
    branch: {
      type: Object,
      required: true,
    },
    existingModifierSet: {
      type: Object,
      default: null,
    },
  },
  emits: ['close'],
  data() {
    return {
      form: useForm({
        autoTranslate: this.existingModifierSet
          ? cloneDeep(this.existingModifierSet.autoTranslate)
          : {},
        name: this.existingModifierSet
          ? cloneDeep(this.existingModifierSet.name)
          : {},
        minSelection: this.existingModifierSet
          ? cloneDeep(this.existingModifierSet.minSelection)
          : 1,
        maxSelection: this.existingModifierSet
          ? cloneDeep(this.existingModifierSet.maxSelection)
          : 1,
        modifiers: this.existingModifierSet
          ? cloneDeep(this.existingModifierSet.modifiers)
          : [],
        branchId: this.branch.id,
      }),
    };
  },
  computed: {
    autoTranslatePath() {
      return 'form.autoTranslate';
    },
    translatablePaths() {
      return [
        'form.name',
        ...this.form.modifiers.map(
          (modifier, modifierIndex) => `form.modifiers.${modifierIndex}.name`,
        ),
      ];
    },
  },
  mounted() {
    if (this.form.modifiers.length === 0) {
      this.addModifier();
    }
  },
  methods: {
    addModifier() {
      this.form.modifiers.push({
        id: null,
        name: {}, // translatable
        price: null,
      });
    },
    deleteModifier(modifierIndex) {
      this.form.modifiers.splice(modifierIndex, 1);
      this.form.clearErrors(
        ...Object.keys(this.form.errors).filter((field) =>
          field.startsWith('modifiers'),
        ),
      );
    },
    async submit() {
      if (!this.existingModifierSet) {
        await this.form.post('/modifier-sets', {
          onSuccess: () => {
            this.$emit('close');
            this.$toast.success('Saved');
          },
          onError: () => {
            this.$toast.error('Please fix all highlighted fields');
          },
          preserveScroll: true,
        });
      } else {
        await this.form.put(`/modifier-sets/${this.existingModifierSet.id}`, {
          onSuccess: () => {
            this.$emit('close');
            this.$toast.success('Saved');
          },
          onError: () => {
            this.$toast.error('Please fix all highlighted fields');
          },
          preserveScroll: true,
        });
      }
    },
  },
};
</script>
