<template>
  <AuthLayout title="Let us know more about your restaurant">
    <form @submit.prevent="submit">
      <h1 class="text-center text-2xl font-bold text-gray-600">
        Add your first menu item
      </h1>

      <div class="mt-2 text-center text-sm text-gray-500">Step 3 of 3</div>

      <div class="mt-6 space-y-4">
        <div>
          <AInput
            v-model="form.categoryName"
            label="Category name"
            type="text"
            :error="form.errors.categoryName"
            placeholder="Appetizers"
          />
        </div>
        <div>
          <AInput
            v-model="form.productName"
            label="Product name"
            type="text"
            :error="form.errors.productName"
            placeholder="Hummus"
          />
        </div>
        <div>
          <AInput
            v-model="form.productPrice"
            type="number"
            :step="0.01"
            placeholder="25.00"
            :error="form.errors.productPrice"
            label="Product price"
            class="mr-4"
          />
        </div>

        <div>
          <ALabel optional for="image"> Product image </ALabel>

          <div
            v-if="imageUrl"
            class="mb-2 mt-1 h-32 w-32 overflow-hidden rounded"
          >
            <img class="h-full w-full object-cover" :src="imageUrl" alt="" />
          </div>

          <input
            id="image"
            ref="file"
            class="hidden"
            type="file"
            accept="image/*"
            @input="
              form.image =
                $event.target.files.length > 0
                  ? $event.target.files[0]
                  : form.image
            "
          />

          <div class="mt-1">
            <AButton variant="outline-primary" @click="$refs.file.click()">
              Choose image
            </AButton>
          </div>

          <AError v-if="form.errors.image" class="mt-1">
            {{ form.errors.image }}
          </AError>
        </div>

        <div>
          <ALabel optional class="mb-1"> Product description </ALabel>
          <HtmlEditor v-model="form.productDescription" />
          <AError v-if="form.errors.productDescription" class="mt-1">{{
            form.errors.productDescription
          }}</AError>
        </div>
      </div>

      <div class="mt-8">
        <button
          type="submit"
          :disabled="form.processing"
          :data-loading="form.processing"
          class="btn btn-primary w-full"
        >
          Continue
        </button>

        <button
          type="button"
          class="btn btn-outline-primary mt-2 w-full"
          :disabled="skipForm.processing"
          :data-loading="skipForm.processing"
          @click="skipForm.get('/')"
        >
          Skip and add later
        </button>
      </div>
    </form>
  </AuthLayout>
</template>

<script>
import { useForm } from '@inertiajs/vue3';
import AuthLayout from '@app/Shared/AuthLayout.vue';
import AInput from '@app/Shared/AInput.vue';
import AButton from '@app/Shared/AButton.vue';
import AError from '@app/Shared/AError.vue';
import ALabel from '@app/Shared/ALabel.vue';
import HtmlEditor from '@app/Shared/HtmlEditor.vue';

export default {
  components: {
    HtmlEditor,
    ALabel,
    AError,
    AButton,
    AInput,
    AuthLayout,
  },
  data() {
    return {
      form: useForm({
        categoryName: '',
        productName: '',
        productDescription: '',
        productPrice: null,
        image: null,
      }),
      skipForm: useForm({}),
    };
  },
  computed: {
    imageUrl() {
      if (this.form.image) {
        return URL.createObjectURL(this.form.image);
      }

      return null;
    },
  },
  watch: {
    'form.categoryName'() {
      this.form.clearErrors('categoryName');
    },
    'form.productName'() {
      this.form.clearErrors('productName');
    },
    'form.productPrice'() {
      this.form.clearErrors('productPrice');
    },
    'form.productDescription'() {
      this.form.clearErrors('productDescription');
    },
    'form.image'() {
      this.form.clearErrors('image');
    },
  },
  mounted() {
    //
  },
  methods: {
    async submit() {
      await this.form.post('/onboarding/menu', {
        onSuccess: () => {
          //
        },
      });
    },
  },
};
</script>
