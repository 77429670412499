<template>
  <DashboardWrapper title="Restaurant settings">
    <RestaurantSettingsTabs />

    <ACard class="mb-4">
      <form class="space-y-4" @submit.prevent="submit">
        <div>
          <ALabel for="banner"> Restaurant banner </ALabel>

          <MediaFile
            id="banner"
            class="mt-1"
            :file="form.banner"
            :image-url="
              restaurant.banner && !form.deleteBanner ? restaurant.banner : null
            "
            :allowed-extensions="['jpeg', 'jpg', 'png', 'webp']"
            :max-size-in-megabytes="8"
            :preview-aspect-ratio="1200 / 700"
            @file="
              (file) => {
                form.banner = file;
                form.deleteBanner = false;
              }
            "
            @delete="
              () => {
                form.banner = null;
                form.deleteBanner = true;
              }
            "
          />

          <div class="mt-1 text-xs text-gray-500">
            Recommended resolution: 1200x700px
          </div>
          <div class="mt-1 text-xs text-gray-500">
            It is displayed at the top of your menu.
          </div>

          <AError v-if="form.errors.banner" class="mt-1">
            {{ form.errors.banner }}
          </AError>
        </div>

        <div>
          <ALabel for="logo"> Restaurant logo </ALabel>

          <MediaFile
            id="logo"
            class="mt-1"
            :file="form.logo"
            :image-url="
              restaurant.logo && !form.deleteLogo ? restaurant.logo : null
            "
            :allowed-extensions="['jpeg', 'jpg', 'png', 'webp']"
            :max-size-in-megabytes="8"
            @file="
              (file) => {
                form.logo = file;
                form.deleteLogo = false;
              }
            "
            @delete="
              () => {
                form.logo = null;
                form.deleteLogo = true;
              }
            "
          />

          <AError v-if="form.errors.logo" class="mt-1">
            {{ form.errors.logo }}
          </AError>
        </div>

        <ASelect
          v-model="form.menuItemsDisplayType"
          label="Layout type"
          :options="[
            { type: 'grid', label: 'Grid' },
            { type: 'grid-extended', label: 'Grid Extended' },
            { type: 'list', label: 'List' },
          ]"
          :value-resolver="(layoutType) => layoutType.type"
          :text-resolver="(layoutType) => layoutType.label"
          :error="form.errors.menuItemsDisplayType"
        />

        <div class="flex flex-wrap">
          <div class="mb-4 mr-12 space-y-4">
            <div>
              <AColorInput v-model="form.primaryColor" label="Primary color" />
            </div>

            <div>
              <AColorInput
                v-model="form.backgroundColor"
                label="Background color"
              />
            </div>

            <div>
              <AColorInput v-model="form.textColor" label="Text color" />
            </div>

            <div>
              <AColorInput
                v-model="form.buttonTextColor"
                label="Button text color"
              />
            </div>
          </div>
          <div class="w-[300px]">
            <div class="mb-1">
              <ALabel>Live preview of colors</ALabel>
            </div>
            <div
              :style="{
                background: form.backgroundColor,
                color: form.textColor,
              }"
              class="rounded-lg border px-10 pb-10 pt-6"
            >
              <div
                :style="{ color: form.textColor }"
                class="mb-4 text-center text-lg font-semibold"
              >
                Text color
              </div>
              <div
                class="rounded-full px-4 py-3 text-center font-semibold"
                :style="{
                  background: form.primaryColor,
                  color: form.buttonTextColor,
                }"
              >
                Button
              </div>
            </div>

            <div class="mt-1 text-xs text-gray-500">
              Make sure there is enough contrast between the colors you choose
              to make it easy to read.
            </div>
          </div>
        </div>

        <div>
          <ASelect
            v-model="form.primaryFontId"
            label="Primary font"
            :options="[
              { value: '', label: 'Default' },
              ...fonts.map((font) => ({
                value: font.id,
                label: font.name,
              })),
            ]"
            :value-resolver="(option) => option.value"
            :text-resolver="(option) => option.label"
            :error="form.errors.primaryFontId"
          />

          <button
            type="button"
            class="mt-0.5 text-xs text-gray-500 hover:text-gray-600"
            @click="modals.uploadPrimaryCustomFont = true"
          >
            + Upload custom font
          </button>
        </div>

        <div>
          <ASelect
            v-model="form.secondaryFontId"
            label="Secondary font"
            :options="[
              { value: '', label: 'Default' },
              ...fonts.map((font) => ({
                value: font.id,
                label: font.name,
              })),
            ]"
            :value-resolver="(option) => option.value"
            :text-resolver="(option) => option.label"
            :error="form.errors.secondaryFontId"
          />

          <button
            type="button"
            class="mt-0.5 text-xs text-gray-500 hover:text-gray-600"
            @click="modals.uploadSecondaryCustomFont = true"
          >
            + Upload custom font
          </button>
        </div>

        <div>
          <ASelect
            v-model="form.isWelcomePageEnabled"
            label="Welcome page"
            :options="[
              { value: '1', label: 'Enabled' },
              { value: '0', label: 'Disabled' },
            ]"
            :value-resolver="(option) => option.value"
            :text-resolver="(option) => option.label"
            :error="form.errors.isWelcomePageEnabled"
          />
        </div>

        <div
          class="sticky bottom-0 z-10 flex w-full items-center bg-white py-3"
        >
          <AButton type="submit" class="mt-3" :loading="form.processing">
            Save
          </AButton>
          <div v-if="form.isDirty" class="ml-3 text-gray-400">
            (has unsaved changes)
          </div>
        </div>
      </form>
    </ACard>

    <UploadCustomFontModal
      v-if="modals.uploadPrimaryCustomFont"
      @upload="
        (font) => {
          $inertia.reload({ preserveScroll: true });
          form.primaryFontId = font.id;
        }
      "
      @close="modals.uploadPrimaryCustomFont = false"
    />

    <UploadCustomFontModal
      v-if="modals.uploadSecondaryCustomFont"
      @upload="
        (font) => {
          $inertia.reload({ preserveScroll: true });
          form.secondaryFontId = font.id;
        }
      "
      @close="modals.uploadSecondaryCustomFont = false"
    />
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import { useForm } from '@inertiajs/vue3';
import ASelect from '@app/Shared/ASelect.vue';
import AError from '@app/Shared/AError.vue';
import AButton from '@app/Shared/AButton.vue';
import ALabel from '@app/Shared/ALabel.vue';
import AColorInput from '@app/Shared/AColorInput.vue';
import ACard from '@app/Shared/ACard.vue';
import RestaurantSettingsTabs from '@app/Shared/Tabs/RestaurantSettingsTabs.vue';
import MediaFile from '@app/Shared/MediaFile.vue';
import UploadCustomFontModal from '@app/Modals/UploadCustomFontModal.vue';

export default {
  components: {
    UploadCustomFontModal,
    MediaFile,
    ACard,
    AColorInput,
    ALabel,
    AButton,
    AError,
    ASelect,
    DashboardWrapper,
    RestaurantSettingsTabs,
  },
  props: {
    global: {
      type: Object,
      required: true,
    },
    restaurant: {
      type: Object,
      required: true,
    },
    fonts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: useForm({
        banner: null,
        deleteBanner: false,
        logo: null,
        deleteLogo: false,
        menuItemsDisplayType: this.restaurant.menuItemsDisplayType,
        primaryColor: this.restaurant.primaryColor,
        backgroundColor: this.restaurant.backgroundColor,
        textColor: this.restaurant.textColor,
        buttonTextColor: this.restaurant.buttonTextColor,
        primaryFontId: this.restaurant.primaryFontId,
        secondaryFontId: this.restaurant.secondaryFontId,
        isWelcomePageEnabled: this.restaurant.isWelcomePageEnabled ? '1' : '0',
      }),
      modals: {
        uploadPrimaryCustomFont: false,
        uploadSecondaryCustomFont: false,
      },
    };
  },
  methods: {
    submit() {
      this.form.put('/restaurant', {
        preserveScroll: true,
        onError: () => {
          this.$toast.error('Please fix all highlighted fields');
        },
        onSuccess: () => {
          this.$toast.success('Saved');
        },
      });
    },
  },
};
</script>
