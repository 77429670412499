<template>
  <DashboardWrapper title="How it works">
    <ACard class="mb-4">
      <p class="mb-4">
        This video will walk you through the basics of using Apetito Menu and
        creating your own menu.
      </p>
      <div class="mb-4 max-w-lg">
        <div class="aspect-h-9 aspect-w-16 overflow-hidden rounded-lg border">
          <iframe
            src="https://www.youtube.com/embed/aRg47fyRs2k?rel=0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
      <div>
        Still have questions?
        <Link href="/support" class="underline">Contact us.</Link>
      </div>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import { Link } from '@inertiajs/vue3';

export default {
  components: {
    ACard,
    DashboardWrapper,
    Link,
  },
};
</script>
