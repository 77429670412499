<template>
  <div>
    <div class="flex items-center">
      <ALabel v-if="label" :for="id" :mandatory="mandatory">
        {{ label }}
      </ALabel>

      <svg
        v-if="tooltip"
        v-tippy="{ content: tooltip }"
        aria-hidden="true"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        class="text-gray-400 transition hover:text-gray-500 focus:outline-none"
        style="
          width: 0.9em;
          height: 0.9em;
          margin-left: 0.4em;
          margin-top: 0.1em;
        "
      >
        <path
          d="M9 8a1 1 0 0 0-1-1H5.5a1 1 0 1 0 0 2H7v4a1 1 0 0 0 2 0zM4 0h8a4 4 0 0 1 4 4v8a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4zm4 5.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"
          fill-rule="evenodd"
          fill="currentColor"
        ></path>
      </svg>
    </div>
    <div class="relative mt-1">
      <select
        :id="id"
        class="block w-full appearance-none rounded-md border bg-white py-2 pl-3 pr-10 text-base focus:outline-none disabled:cursor-not-allowed disabled:bg-gray-100"
        :class="[
          error
            ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-1 focus:ring-red-500'
            : 'border-gray-300 focus:border-lime-500 focus:ring-1 focus:ring-lime-500',
          [null, '', undefined].includes(modelValue) && emptyItemText
            ? 'text-gray-400'
            : '',
        ]"
        :value="modelValue"
        :disabled="disabled"
        @change="$emit('update:modelValue', $event.target.value)"
      >
        <option v-if="emptyItemText" :disabled="emptyItemDisabled" value="">
          {{ emptyItemText }}
        </option>
        <option
          v-for="(option, optionIndex) in options"
          :key="optionIndex"
          :value="valueResolver(option)"
        >
          {{ textResolver(option) }}
        </option>
      </select>

      <div class="absolute bottom-0 right-0 top-0 flex items-center pr-3">
        <CaretDownBold class="size-4 text-gray-500" />
      </div>
    </div>

    <AError v-if="error" class="mt-1">
      {{ error }}
    </AError>
  </div>
</template>

<script>
import AError from './AError.vue';
import ALabel from './ALabel.vue';
import { v4 as uuid } from 'uuid';
import CaretDownBold from '@/phosphoricons/CaretDownBold.vue';

export default {
  components: { CaretDownBold, ALabel, AError },
  props: {
    modelValue: {
      type: [String, Number],
      default: null,
    },
    options: {
      type: Array,
      required: true,
    },
    valueResolver: {
      type: Function,
      default: (option) => option['value'],
    },
    textResolver: {
      type: Function,
      default: (option) => option['text'],
    },
    label: {
      type: String,
      default: null,
    },
    error: {
      type: String,
      default: null,
    },
    // todo: better name?
    emptyItemText: {
      type: String,
      default: null,
    },
    // todo: better name?
    emptyItemDisabled: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: null,
    },
    mandatory: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      id: null,
    };
  },
  created() {
    this.id = `select-native-${uuid()}`;
  },
};
</script>
