<template>
  <DashboardWrapper :title="`Subscription for ${$page.props._restaurant.name}`">
    <ACard>
      <div
        v-if="trialEndsAt || subscriptionCancelledAt"
        class="mb-4 rounded-lg px-6 py-4"
        :class="[
          isExpiredTrial || subscriptionCancelledAt
            ? 'bg-red-500 text-white'
            : 'border border-yellow-100 bg-yellow-50 text-yellow-900',
        ]"
      >
        <div class="text-lg font-semibold">
          <template v-if="subscriptionCancelledAt">
            Your subscription for {{ $page.props._restaurant.name }} was
            cancelled {{ subscriptionCancelledAt.toRelative() }}.
          </template>
          <template v-else-if="isExpiredTrial">
            Your free trial for {{ $page.props._restaurant.name }} has ended
            {{ trialEndsAt.toRelative() }}.
          </template>
          <template v-else>
            Your free trial for {{ $page.props._restaurant.name }} ends
            {{ trialEndsAt.toRelative() }}.
          </template>
        </div>
        <div class="mt-1 text-sm">
          <template v-if="isExpiredTrial || subscriptionCancelledAt">
            <strong>People can't view your menu anymore.</strong>
            Once you subscribe below, your menu will become active immediately.
          </template>
          <template v-else>
            To keep your menu active, subscribe below before your free trial
            ends.
          </template>
        </div>
      </div>

      <div class="text-lg font-bold">Choose your country</div>

      <div class="mt-1">
        <div class="flex">
          <ASelect
            v-model="countryCode"
            :options="countries"
            :value-resolver="(option) => option.code"
            :text-resolver="(option) => option.name"
          />
        </div>

        <div class="mt-1 text-sm text-gray-500">
          This country will appear on your invoice.
        </div>
      </div>

      <div class="mt-6 text-lg font-bold">Choose your plan</div>

      <div class="mt-1">
        <div v-if="filteredPlans.length === 0" class="text-gray-500">
          There are no available plans for your restaurant in this country.
          <Link href="/support" class="underline">Contact us.</Link>
        </div>
        <div v-else>
          <div class="mb-2 mt-4 flex max-w-4xl justify-center">
            <div class="flex flex-wrap">
              <button
                v-for="(intervalTab, intervalTabIndex) in intervalTabs"
                :key="intervalTabIndex"
                type="button"
                class="relative mb-4 mr-3 w-[90px] rounded-xl border-2 py-2 transition last:mr-0 hover:bg-gray-50 focus:outline-none max-sm:text-sm sm:w-[110px]"
                :class="
                  interval === intervalTab.interval &&
                  intervalCount === intervalTab.intervalCount
                    ? 'border-lime-500 font-medium shadow-lg shadow-lime-500/20'
                    : 'border-gray-200'
                "
                @click="
                  () => {
                    interval = intervalTab.interval;
                    intervalCount = intervalTab.intervalCount;
                  }
                "
              >
                {{ intervalTab.label }}
                <span
                  class="absolute -right-3 -top-2.5 rounded-full border border-lime-500/50 bg-lime-50 px-2 text-xs font-medium text-lime-700 empty:hidden sm:-top-3 sm:text-sm"
                  >{{ intervalTab.badge }}</span
                >
              </button>
            </div>
          </div>
          <div class="overflow-x-auto max-lg:-mx-6">
            <table class="max-w-4xl">
              <thead>
                <tr>
                  <th style="width: 210px">
                    <div
                      class="flex flex-col items-center justify-center md:hidden"
                    >
                      <HandSwipeLeftLight class="h-8 w-8 text-gray-600" />
                      <div class="mt-1 px-4 text-xs font-normal text-gray-500">
                        Swipe to see all plans
                      </div>
                    </div>
                  </th>
                  <th
                    v-for="plan in filteredPlans"
                    :key="plan.type"
                    class="px-3 py-4"
                    style="width: 190px"
                  >
                    <div class="text-left">
                      <div class="flex items-center">
                        <div class="text-xl font-semibold">
                          {{ plan.name }}
                        </div>
                      </div>
                      <div class="mt-0.5 min-w-[112px] font-normal">
                        <span class="font-semibold">
                          {{ plan.currencyCode }}
                          {{ pricePerMonth(plan) }} </span
                        ><span class="text-sm text-gray-500">/month</span>
                      </div>
                      <div
                        class="min-w-[100px] text-xs font-normal text-gray-500"
                      >
                        Billed {{ plan.currencyCode }} {{ plan.price }} every
                        {{ everyPeriodName(plan) }}
                      </div>
                      <div>
                        <button
                          type="button"
                          class="btn btn-primary btn-sm mt-2"
                          :disabled="checkoutForm.processing"
                          :data-loading="checkoutForm.plan_id === plan.id"
                          @click="
                            () => {
                              checkoutForm.plan_id = plan.id;
                              checkoutForm.country_code = countryCode;
                              checkoutForm.post('/billing/checkout');
                            }
                          "
                        >
                          Choose <span class="max-sm:hidden">&nbsp;plan</span>
                        </button>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(feature, featureIndex) in features"
                  :key="featureIndex"
                  class="border-y border-gray-200 odd:bg-gray-50/50"
                >
                  <td class="px-3 py-2.5">
                    <FeatureName :feature="feature" />
                  </td>
                  <td
                    v-for="plan in filteredPlans"
                    :key="plan.type"
                    class="px-3 py-2"
                  >
                    <FeatureAvailability
                      :feature="feature"
                      :benefits="getPlanBenefits(plan)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div class="mt-10 text-lg font-bold">Frequently asked questions</div>

      <div class="mt-4 max-w-3xl space-y-4">
        <FaqItem>
          <template #question> Can I cancel at any time? </template>
          <template #answer>
            Yes, of course! There is no commitment with Apetito Menu. You can
            cancel your subscription at any time without penalty.
          </template>
        </FaqItem>

        <FaqItem>
          <template #question> Can I switch between plans later? </template>
          <template #answer>
            Yes, of course! You can switch between plans (Basic/Plus/Pro) and
            intervals (monthly/quarterly/yearly) at any time. If you're changing
            to a plan with a higher price in the middle of a billing cycle,
            you'll only be charged the difference.
          </template>
        </FaqItem>

        <FaqItem>
          <template #question>
            Can I get a discount if I pay yearly or quarterly?
          </template>
          <template #answer>
            Yes, you can! You get 16% discount when you pay yearly, 8% discount
            when you pay quarterly.
          </template>
        </FaqItem>

        <FaqItem>
          <template #question> What payment methods can I use? </template>
          <template #answer>
            We accept most credit/debit cards (including Visa, Mastercard,
            American Express, Discover & Diners Club, UnionPay, JCB) and digital
            wallets (Apple Pay, Google Pay). If you're experiencing any troubles
            with your payment method,
            <Link href="/support" class="underline">contact us</Link>.
          </template>
        </FaqItem>

        <FaqItem>
          <template #question> Can I get a tax invoice? </template>
          <template #answer>
            Yes, of course! You can download tax invoices from your account
            after each payment. If you need your company details on the invoice,
            you can request it after the payment is done.
          </template>
        </FaqItem>

        <FaqItem>
          <template #question>
            Is it possible to manage multiple restaurants?
          </template>
          <template #answer>
            Yes, of course! You can add all your restaurants to the same account
            and switch between them without having to log out. Each restaurant
            requires its own subscription plan and has its own QR code, menu
            items, design, users, etc.
          </template>
        </FaqItem>

        <FaqItem>
          <template #question>
            Can I talk to someone if I have more questions?
          </template>
          <template #answer>
            Yes, of course! You can contact us
            <Link href="/support" class="underline">here</Link>.
          </template>
        </FaqItem>
      </div>
    </ACard>
  </DashboardWrapper>
</template>

<script>
import DashboardWrapper from '@app/Shared/DashboardWrapper.vue';
import ACard from '@app/Shared/ACard.vue';
import ASelect from '@app/Shared/ASelect.vue';
import { Link, useForm } from '@inertiajs/vue3';
import HandSwipeLeftLight from '@/phosphoricons/HandSwipeLeftLight.vue';
import getFeatures from '@/utils/getFeatures';
import FeatureName from '@/app/Shared/FeatureName.vue';
import FeatureAvailability from '@/app/Shared/FeatureAvailability.vue';
import FaqItem from '@/app/Shared/FaqItem.vue';
export default {
  components: {
    FaqItem,
    FeatureAvailability,
    FeatureName,
    HandSwipeLeftLight,
    Link,
    ASelect,
    ACard,
    DashboardWrapper,
  },
  props: {
    plans: {
      type: Array,
      required: true,
    },
    countries: {
      type: Array,
      required: true,
    },
    defaultCountryCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      checkoutForm: useForm({
        plan_id: null,
        country_code: null,
      }),
      countryCode: this.defaultCountryCode,
      interval: 'month',
      intervalCount: 1,
    };
  },
  computed: {
    intervalTabs() {
      return [
        {
          label: 'Monthly',
          interval: 'month',
          intervalCount: 1,
        },
        {
          label: 'Quarterly',
          interval: 'month',
          intervalCount: 3,
          badge: 'Save 8%',
        },
        {
          label: 'Yearly',
          interval: 'year',
          intervalCount: 1,
          badge: 'Save 16%',
        },
      ].filter((intervalTab) => {
        return this.countryPlans.some((plan) => {
          return (
            plan.interval === intervalTab.interval &&
            plan.intervalCount === intervalTab.intervalCount
          );
        });
      });
    },
    features() {
      return getFeatures();
    },
    countryPlans() {
      return this.plans.filter((plan) => {
        return this.countryCode === 'AE'
          ? plan.currencyCode === 'AED'
          : plan.currencyCode !== 'AED';
      });
    },
    filteredPlans() {
      return this.countryPlans.filter((plan) => {
        return (
          plan.interval === this.interval &&
          plan.intervalCount === this.intervalCount
        );
      });
    },
    trialEndsAt() {
      return this.$page.props._restaurant.trialEndsAt
        ? this.$luxon.DateTime.fromISO(this.$page.props._restaurant.trialEndsAt)
        : null;
    },
    subscriptionCancelledAt() {
      return this.$page.props._restaurant.subscriptionCancelledAt
        ? this.$luxon.DateTime.fromISO(
            this.$page.props._restaurant.subscriptionCancelledAt,
          )
        : null;
    },
    isExpiredTrial() {
      return this.trialEndsAt && this.$luxon.DateTime.now() > this.trialEndsAt;
    },
  },
  watch: {
    countryCode: {
      immediate: true,
      handler() {
        if (this.intervalTabs.length > 0) {
          this.interval = this.intervalTabs[0].interval;
          this.intervalCount = this.intervalTabs[0].intervalCount;
        }
      },
    },
  },
  methods: {
    getPlanBenefits(plan) {
      return {
        maxLanguages: plan.maxLanguages,
        allowCustomHostname: plan.allowCustomHostname,
      };
    },
    pricePerMonth(plan) {
      if (plan.interval === 'year') {
        return plan.price / 12 / plan.intervalCount;
      }
      if (plan.interval === 'month') {
        return plan.price / plan.intervalCount;
      }
      return null;
    },
    everyPeriodName(plan) {
      if (plan.interval === 'year' && plan.intervalCount === 1) {
        return 'year';
      }
      if (plan.interval === 'month' && plan.intervalCount === 3) {
        return 'quarter';
      }
      if (plan.interval === 'month' && plan.intervalCount === 1) {
        return 'month';
      }
      return `${plan.intervalCount} ${plan.interval}s`;
    },
  },
};
</script>
